import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";

export const fetchProperties = createAsyncThunk(
	'properties/fetchProperties',
	async ({accommodation}) => {
		const response = await getHelper(`${BASE_URL}/properties?category=general`,
			{Authorization:`lid ${accommodation}`});  // adjust the URL accordingly
		return response;
	}
);

const initialState = {
	status: 'idle',
	error: null,
	properties: {},
	googleTagManagerId: null,
};

const propertiesSlice = createSlice({
	name: 'properties',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProperties.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchProperties.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.properties = action.payload[0];
				state.googleTagManagerId = action.payload[0]?.values?.googleTagManagerId || "G-MJFY5YEE5C"
			})
			.addCase(fetchProperties.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export default propertiesSlice.reducer;
