import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper, postHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";
import {setLocalStorageWithTimer} from "../../general/util";

export const fetchUser = createAsyncThunk(
	'user/fetchUser',
	async ({accommodation, type}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/launcher/info`,
				{Authorization: `token ${type}`});
			dispatch(setIsTestUser(
				response.user.reservationNumber === response.user.firstName &&
				response.user.firstName === response.user.roomNumber
			))
			dispatch(setToken(type));
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error fetching user');
		}
	}
);

export const send2FACode = createAsyncThunk(
	'user/send2FACode',
	async ({accommodation, mobile}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/routee/sendCodeToCustomer/mobileNumber/${mobile}/accommodationId/${accommodation}`,
				{Authorization: `lid ${accommodation}`});
			dispatch(setMobile(mobile));
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error initiating 2FA');
		}
	}
);

export const verify2FACode = createAsyncThunk(
	'user/verify2FACode',
	async ({accommodation, payload}, {rejectWithValue, getState}) => {
		try {
			const mobile = getState().user.mobile;
			const response = await postHelper(`${BASE_URL}/routee/verifyCode`, payload, {
				Authorization: `token ${accommodation}-en`,
				"Content-Type": "application/json",
			},);
			
			if (response === true) {
				setLocalStorageWithTimer(`verifiedMobile-${accommodation}`, mobile, 900000)
			}
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error verifying 2FA');
		}
	}
);

export const createAccount = createAsyncThunk(
	'user/createAccount',
	async ({accommodation, payload}, {rejectWithValue}) => {
		try {
			const response = await postHelper(`${BASE_URL}/loyaltyClub/acm/${accommodation}`, payload, {
				"Content-Type": "application/json",
				"Content-Length": `${payload.length}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const fetchUserByRoomNumber = createAsyncThunk(
	'user/fetchUserByRoomNumber',
	async ({accommodation, roomNumber, firstFourLettersOfLastNameOrDepartureDate}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/user/infoByRoomNumberAndLastNameOrDepartureDate/${roomNumber}/${firstFourLettersOfLastNameOrDepartureDate}`, {
				Authorization: `lid ${accommodation}`
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);


export const fetchUserByReservationNumber = createAsyncThunk(
	'user/fetchUserByReservationNumber',
	async ({accommodation, reservationNumber}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/guests/findByReservationNumber/${reservationNumber}`, {
				Authorization: `lid ${accommodation}`,
			});
			dispatch(setIsTestUser(response.isTestUser || false))
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const fetch2FAUser = createAsyncThunk(
	'user/fetch2FAUser',
	async ({accommodation, mobile2FA, token}, {getState, dispatch, rejectWithValue}) => {
		const mobile = mobile2FA || getState().user.mobile;
		try {
			let token2FA;
			if (mobile) {
				const tokenExist = await dispatch(checkUserIfExistAndGetToken({mobile, accommodation}));
				if (!tokenExist.payload) {
					return rejectWithValue("No token found for this mobile number");
				}
				token2FA = tokenExist.payload;
				dispatch(setToken(token2FA));
			} else if (token) {
				const tokenIsExpired = await dispatch(checkIfTokenHasExpired({accommodation, token}));
				if (tokenIsExpired.payload) {
					return rejectWithValue("Token expired");
				}
				token2FA = token;
				dispatch(setToken(token2FA));
			} else {
				return rejectWithValue("No mobile or token provided");
			}
			
			const data = await dispatch(fetchLoyaltyMemberWithToken({token: token2FA, accommodation}));
			if (!data) {
				return rejectWithValue("Failed to fetch loyalty member");
			}
			localStorage.setItem(`token-${accommodation}`, token2FA);
			return data;
		} catch (error) {
			return rejectWithValue(error.message || 'Error logging in with 2FA');
		}
	}
);


export const fetchLoyaltyMemberWithToken = createAsyncThunk(
	'user/fetchLoyaltyMemberWithToken',
	async ({accommodation, token}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/routee/getLoyaltyMember/token/${token}`, {
				Authorization: `lid ${accommodation}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);


export const checkIfTokenHasExpired = createAsyncThunk(
	'user/checkIfTokenHasExpired',
	async ({accommodation, token}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/routee/checkIfTokenHasExpired/token/${token}`, {
				Authorization: `lid ${accommodation}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const checkUserIfExistAndGetToken = createAsyncThunk(
	'user/checkUserIfExistAndGetToken',
	async ({accommodation, mobile}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/routee/checkIfExistsAndLogIn/mobileNumber/${mobile}/accommodationId/${accommodation}`, {
				Authorization: `lid ${accommodation}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

const initialState = {
	isTestUser: false,
	data: null,
	status: 'idle', // 'loading', 'failed', 'succeeded'
	error: null,
	loggedIn: false,
	code2FASent: false,
	pin2FAVerified: undefined,
	mobile: null,
	token: undefined,
	accountCreationSuccess: undefined,
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logoutUser: (state) => {
			state.data = null;
			state.status = 'idle';
			state.error = null;
			state.loggedIn = false;
			state.tecode2FASent = false;
			state.pin2FAVerified = undefined;
			state.mobile = null;
			state.token = undefined;
		},
		setMobile: (state, action) => {
			state.mobile = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload
		},
		setIsTestUser: (state, action) => {
			state.isTestUser = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
			})
			.addCase(fetchUser.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
			.addCase(send2FACode.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(send2FACode.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(send2FACode.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.code2FASent = action.payload;
			})
			.addCase(verify2FACode.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(verify2FACode.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(verify2FACode.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.pin2FAVerified = action.payload;
			})
			.addCase(fetch2FAUser.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetch2FAUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
				state.pin2FAVerified = undefined;
			})
			.addCase(fetch2FAUser.fulfilled, (state, action) => {
				state.data = action.payload.payload;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
			.addCase(createAccount.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(createAccount.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(createAccount.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.data = action.payload
				state.accountCreationSuccess = true;
				state.error = null;
			})
			.addCase(fetchUserByReservationNumber.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchUserByReservationNumber.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
			})
			.addCase(fetchUserByReservationNumber.fulfilled, (state, action) => {
				state.data = action.payload;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
	}
});

export const {logoutUser, setMobile, setToken, setIsTestUser} = userSlice.actions;
export default userSlice.reducer;
