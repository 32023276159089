import React from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {useAuth} from "../../hooks/useAuth";
import {getQueryParam} from "../../general/util";
import Loader from "./Loader";

const ProtectedRoute = ({element: Component}) => {
	const {loggedIn} = useAuth();
	
	const location = useLocation();
	const {accommodation} = useParams();
	const type = getQueryParam('type');
	const res = getQueryParam('res');
	
	if ((type || res) && !loggedIn) {
		// If there's a type and user is not logged in, don't redirect yet.
		// Show some loading indicator or a message to the user to wait.
		return <Loader/>;
	} else if (!loggedIn) {
		return <Navigate to={{
			pathname: `/${accommodation}/auth/2fa`,
			search: location.search}} state={{from: location}} replace/>;
	}
	
	// If authenticated by any method, render the requested component
	return <Component/>;
};
export default ProtectedRoute