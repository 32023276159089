// For the core logic of your API calls.

import axios from "axios";
import {TIMEOUT} from "./constants";

// Helper function to handle various HTTP methods
const apiHelper = async (method, url, data, headers) => {
	const config = {
		method,
		url,
		data,
		headers,
		timeout: TIMEOUT
	};
	
	try {
		const response = await axios(config);
		return response.data;
	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// outside of the range of 2xx
			throw new Error(`Error: ${error.response.status} ${error.response.data.message || error.message}`);
		} else if (error.request) {
			// The request was made but no response was received
			throw new Error("No response from server.");
		} else {
			// Something happened in setting up the request that triggered an Error
			throw new Error(`Request error: ${error.message}`);
		}
	}
};

export const getHelper = async (url, headers) => {
	return apiHelper("GET", url, null, headers);
};

export const postHelper = async (url, payload, headers) => {
	return apiHelper("POST", url, payload, headers);
};

export const putHelper = async (url, payload, headers) => {
	return apiHelper("PUT", url, payload, headers);
};

export const deleteHelper = async (url, headers) => {
	return apiHelper("DELETE", url, null, headers);
};
